<template>
  <div class="bankInfo">
    <mt-header :title="$t('bankInfo')">
      <router-link to slot="left">
        <mt-button @click="$router.back(-1)" icon="back">{{ $t('back') }}</mt-button>
      </router-link>
      <call-phone slot="right" />
    </mt-header>
    <step-item :step="4" />
    <div class="tips">
      <img src="../../assets/tips.png">
      {{ $t('bankInfoTips') }}
    </div>
    <div class="content">
      <mt-field
        :label="$t('bankAccountHolder')"
        disabled
        v-model="form.name"
      ></mt-field>
      <mt-field
        :label="$t('bank')"
        :placeholder="$t('bankPlaceholder')"
        :state="bankCodeState"
        readonly
        v-model="(Enum.bank.filter(e => e.code == form.bankCode)[0]||{}).name"
        @click.native="onPopupPicker(Enum.bank, (e) => {
          form.bankCode = e.code;
          BEHAVIOR_ADD({
            id: 'P04_C01_I_BANKNAME',
            newValue: form.bankCode
          })
        })"
      >
        <i class="iconfont">&#xe62a;</i>
      </mt-field>
      <mt-field
        :label="$t('bankCardNumber')"
        :placeholder="$t('bankCardNumberPlaceholder')"
        v-model="form.maskedBankCardNo"
        v-mask="'#### #### #### ####'"
        :state="maskedBankCardNoState"
        @blur.native.capture="BEHAVIOR_ADD({
          id: 'P04_C02_I_BANKACCOUNTNO',
          newValue: form.maskedBankCardNo
        })"
      ></mt-field>
      <!-- <mt-field
        :label="$t('atmBankCardNo')"
        :placeholder="$t('atmBankCardNoPlaceholder')"
        v-model="form.atmBankCardNo"
        :state="atmBankCardNoState"
        @blur.native.capture="BEHAVIOR_ADD({
          id: 'P05_C06_I_ATMBANKCARDNO',
          newValue: form.atmBankCardNo
        })"
      ></mt-field> -->
      <mt-field
        :label="$t('idcard')"
        disabled
        v-model="form.idcard"
      ></mt-field>
    </div>
    <div class="bottom">
      <mt-button type="primary" size="large" @click="onPopup">{{
        $t("nextStep")
      }}</mt-button>
    </div>
    <popup-picker ref="popupPicker" />
    <mt-popup v-model="popupVisible">
      <div class="box">
        <div class="title">
          {{ $t('confirmBankCardInfo') }}
        </div>
        <div class="val">
          <div>{{ $t('bankAccountHolder') }}: <b>{{ form.name }}</b></div>
          <div>{{ $t('bank') }}: <b>{{ (Enum.bank.filter(e => e.code == form.bankCode)[0]||{}).name }}</b></div>
          <div>{{ $t('bankCardNumber') }}: <b>{{ form.maskedBankCardNo }}</b></div>
          <div v-if="verifyMark == 'N'" class="tips">
            <img src="../../assets/tips.png">
            {{ $t('verifyMarkTips') }}
          </div>
          <!-- <div>{{ $t('atmBankCardNo') }}: <b>{{ form.atmBankCardNo }}</b></div> -->
          <div>{{ $t('idcard') }}: <b>{{ form.idcard }}</b></div>
        </div>
        <div class="tips">
          <img src="../../assets/tips.png">
          {{ $t('bankInfoPopupTips') }}
        </div>
        <div class="button">
          <mt-button type="primary" size="large" @click="submit">{{
            $t("submit")
          }}</mt-button>
          <mt-button type="default" size="large" @click="popupVisible = false">{{
            $t("modify")
          }}</mt-button>
        </div>
      </div>
    </mt-popup>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { emptyRules, bankCardRules } from "../../utils/rules";
import { step, bankAccount } from '../../utils/api'
import popupPicker from '@/components/popupPicker.vue'
import callPhone from '@/components/callPhone.vue'
import stepItem from '../../components/stepItem.vue'
import mixin from './mixin'
import branch from '../../../node_modules/branch-sdk'
import Vue from 'vue'
import gtmPageView from '../../utils/gtm.js'
import getLocation from '../../utils/gps'
export default {
  name: 'BankInfo',
  mixins: [mixin],
  components: {
    popupPicker,
    callPhone,
    stepItem,
  },
  data () {
    return {
      popupVisible: false,
      verifyMark: 'Y',
      bankAccountName: '',
      form: {
        name: '', // 姓名
        bankCode: '', // 银行
        maskedBankCardNo: '', // 银行卡号
        atmBankCardNo: '', // atm卡号
        idcard: '', // bvn
      }
    }
  },
  computed: {
    ...mapState(["apply", "user"]),
    bankCodeState() {
      this.onStorageItem('bankCode')
      return this.isCheck ? emptyRules(this.form.bankCode) : null;
    },
    maskedBankCardNoState() {
      this.onStorageItem('maskedBankCardNo')
      return this.isCheck ? bankCardRules(this.form.maskedBankCardNo.replace(/\s/g, '')) : null;
    },
    atmBankCardNoState() {
      this.onStorageItem('atmBankCardNo')
      return this.isCheck ? emptyRules(this.form.atmBankCardNo) : null;
    },
  },
  watch: {
    popupVisible(val) {
      if (val) {
        this.$axios({
          method: "post",
          url: bankAccount,
          data: {
            applyId: this.user.applyId,
            bankCode: this.form.bankCode,
            bankAccount: this.form.maskedBankCardNo.replace(/\s/g, ''),
            atmBankCardNo: this.form.atmBankCardNo.replace(/\s/g, ''),
            name: this.form.name,
          }
        })
          .then((e) => {
            if (e.status.code == "000") {
              this.verifyMark = (e.body||{}).verifyMark || 'N'
              if (this.verifyMark == 'Y') {
                this.bankAccountName = e.body.bankAccountName ?? '';
              }
            }
          })
          .catch(() => {});
      }
    }
  },
  created() {
    gtmPageView(this)
    Object.keys(this.form).forEach((val) => {
      if (this.apply[val]) {
        this.form[val] = this.apply[val]
      }
    })
    this.form.name = this.user.name
    this.form.idcard = this.user.idcard
    this.init()
  },
  mounted() {
    this.BEHAVIOR_ADD({
      id: 'P04_C00'
    })
  },
  destroyed() {
    this.BEHAVIOR_ADD({
      id: 'P04_C99'
    })
    this.BEHAVIOR_SEND()
  },
  methods: {
    ...mapMutations(["SET_USER"]),
    ...mapMutations('behavior', ["BEHAVIOR_ADD"]),
    ...mapActions('behavior', ["BEHAVIOR_SEND"]),
    init() {
      this.GET_BANK(); // 获取银行
    },
    // 校验输入内容
    validation(list) {
      if (list.indexOf('bankCode') >= 0 && this.bankCodeState != "success") {
        return this.$t('bankPlaceholder')
      }
      if (list.indexOf('maskedBankCardNo') >= 0 && this.maskedBankCardNoState != "success") {
        return this.maskedBankCardNoState == 'warning'? this.$t('bankCardNumberPlaceholder'): this.$t('bankCardNumberError')
      }
      // if (list.indexOf('atmBankCardNo') >= 0 && this.atmBankCardNoState != "success") {
      //   return this.$t('atmBankCardNoPlaceholder')
      // }
    },
    onPopup() {
      this.BEHAVIOR_ADD({
        id: 'P04_C98_B_SUBMIT'
      })
      this.isCheck = true;
      var error = this.validation([
        'bankCode',
        'maskedBankCardNo',
        'atmBankCardNo',
      ]);
      if (error) return this.$toast(error);
      this.popupVisible = true
    },
    async submit() {
      this.popupVisible = false
      this.$indicator.open({
        spinnerType: "fading-circle",
      });
      try {
        // 自定义事件
        let pixel = window.Vue.$route.query['pixel'] || localStorage['pixel']
        if (pixel) {
          Vue.analytics.fbq.event('05_BANK_INFO', { content_name: '05_BANK_INFO' })
        }
        // Google Analytics  REALATIONSHIP_AUTH
        if (localStorage['ga']) {
          window.gtag('event', 'BANK_INFO')
        }
      } catch(e) {
        console.log(e)
      }
      let data = {
        authName: "BANK_INFO",
        deviceId: "H5",
        source: "H5",
        channel: "H5",
        gps: await getLocation(),

        applyId: this.user.applyId,
        currentStep: 5,
        totalSteps: 7,
        ...this.form,
        bankCardNo: this.form.maskedBankCardNo.replace(/\s/g, ''),
        bank: (this.Enum.bank.filter(e => e.code == this.form.bankCode)[0]||{}).name,
        bankAccountName: this.bankAccountName,
      }
      await this.$axios({
        method: "post",
        url: step,
        data: data,
      })
        .then(async (e) => {
          if (e.status.code == "000") {
            this.SET_USER({
              ...this.user,
              ...e.body.session.user,
              applyId: e.body.applyId
            })
            this.$router.push('/step/Idcardauth')
          } else {
            this.$toast(e.status.msg);
          }
        })
        .catch(() => {});
      this.$indicator.close();
      branch.logEvent('05_BANK_INFO', { metadata: data })
    }
  }
}
</script>

<style lang="scss" scoped>
.bankInfo {
  > .tips {
    background-color: #FFFDE3;
  }
  .tips {
    padding: 10px;
    font-size: 12px;
    color: #EC5276;
    img {
      height: 16px;
      vertical-align: sub;
      margin: 0 5px;
    }
  }
  > .bottom {
    padding: 40px 20px 20px 20px;
  }
  .box {
    padding: 10px 15px;
    background-color: white;
    width: 300px;
    .title {
      font-size: 14px;
      font-weight: 600;
      text-align: center;
      padding: 10px;
    }
    .val {
      font-size: 14px;
      div {
        padding: 5px 0px;
      }
      .tips {
        font-weight: bold;
        padding: 0;
      }
    }
    .button {
      padding: 10px;
      display: flex;
      button {
        margin: 0px 10px;
      }
    }
  }
}
</style>
