var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bankInfo"},[_c('mt-header',{attrs:{"title":_vm.$t('bankInfo')}},[_c('router-link',{attrs:{"slot":"left","to":""},slot:"left"},[_c('mt-button',{attrs:{"icon":"back"},on:{"click":function($event){return _vm.$router.back(-1)}}},[_vm._v(_vm._s(_vm.$t('back')))])],1),_c('call-phone',{attrs:{"slot":"right"},slot:"right"})],1),_c('step-item',{attrs:{"step":4}}),_c('div',{staticClass:"tips"},[_c('img',{attrs:{"src":require("../../assets/tips.png")}}),_vm._v(" "+_vm._s(_vm.$t('bankInfoTips'))+" ")]),_c('div',{staticClass:"content"},[_c('mt-field',{attrs:{"label":_vm.$t('bankAccountHolder'),"disabled":""},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}}),_c('mt-field',{attrs:{"label":_vm.$t('bank'),"placeholder":_vm.$t('bankPlaceholder'),"state":_vm.bankCodeState,"readonly":""},nativeOn:{"click":function($event){_vm.onPopupPicker(_vm.Enum.bank, (e) => {
        _vm.form.bankCode = e.code;
        _vm.BEHAVIOR_ADD({
          id: 'P04_C01_I_BANKNAME',
          newValue: _vm.form.bankCode
        })
      })}},model:{value:((_vm.Enum.bank.filter(e => e.code == _vm.form.bankCode)[0]||{}).name),callback:function ($$v) {_vm.$set((_vm.Enum.bank.filter(e => e.code == _vm.form.bankCode)[0]||{}), "name", $$v)},expression:"(Enum.bank.filter(e => e.code == form.bankCode)[0]||{}).name"}},[_c('i',{staticClass:"iconfont"},[_vm._v("")])]),_c('mt-field',{directives:[{name:"mask",rawName:"v-mask",value:('#### #### #### ####'),expression:"'#### #### #### ####'"}],attrs:{"label":_vm.$t('bankCardNumber'),"placeholder":_vm.$t('bankCardNumberPlaceholder'),"state":_vm.maskedBankCardNoState},nativeOn:{"!blur":function($event){return _vm.BEHAVIOR_ADD({
        id: 'P04_C02_I_BANKACCOUNTNO',
        newValue: _vm.form.maskedBankCardNo
      })}},model:{value:(_vm.form.maskedBankCardNo),callback:function ($$v) {_vm.$set(_vm.form, "maskedBankCardNo", $$v)},expression:"form.maskedBankCardNo"}}),_c('mt-field',{attrs:{"label":_vm.$t('idcard'),"disabled":""},model:{value:(_vm.form.idcard),callback:function ($$v) {_vm.$set(_vm.form, "idcard", $$v)},expression:"form.idcard"}})],1),_c('div',{staticClass:"bottom"},[_c('mt-button',{attrs:{"type":"primary","size":"large"},on:{"click":_vm.onPopup}},[_vm._v(_vm._s(_vm.$t("nextStep")))])],1),_c('popup-picker',{ref:"popupPicker"}),_c('mt-popup',{model:{value:(_vm.popupVisible),callback:function ($$v) {_vm.popupVisible=$$v},expression:"popupVisible"}},[_c('div',{staticClass:"box"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$t('confirmBankCardInfo'))+" ")]),_c('div',{staticClass:"val"},[_c('div',[_vm._v(_vm._s(_vm.$t('bankAccountHolder'))+": "),_c('b',[_vm._v(_vm._s(_vm.form.name))])]),_c('div',[_vm._v(_vm._s(_vm.$t('bank'))+": "),_c('b',[_vm._v(_vm._s((_vm.Enum.bank.filter(e => e.code == _vm.form.bankCode)[0]||{}).name))])]),_c('div',[_vm._v(_vm._s(_vm.$t('bankCardNumber'))+": "),_c('b',[_vm._v(_vm._s(_vm.form.maskedBankCardNo))])]),(_vm.verifyMark == 'N')?_c('div',{staticClass:"tips"},[_c('img',{attrs:{"src":require("../../assets/tips.png")}}),_vm._v(" "+_vm._s(_vm.$t('verifyMarkTips'))+" ")]):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.$t('idcard'))+": "),_c('b',[_vm._v(_vm._s(_vm.form.idcard))])])]),_c('div',{staticClass:"tips"},[_c('img',{attrs:{"src":require("../../assets/tips.png")}}),_vm._v(" "+_vm._s(_vm.$t('bankInfoPopupTips'))+" ")]),_c('div',{staticClass:"button"},[_c('mt-button',{attrs:{"type":"primary","size":"large"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("submit")))]),_c('mt-button',{attrs:{"type":"default","size":"large"},on:{"click":function($event){_vm.popupVisible = false}}},[_vm._v(_vm._s(_vm.$t("modify")))])],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }